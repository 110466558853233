.email-keypad-string {
  min-width: 76px !important;
  padding: 14px 20px !important;
}

.email-kaypad-object {
  min-width: 100px !important;
  padding: 14px 20px !important;
}

.email-keypad-modifier {
  min-width: 124px !important;
  padding: 14px 20px !important;
}

.email-keypad-shiftactivated {
  background-color: #246CF6 !important;
  color: #ffffff !important;
}
